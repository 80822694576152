export default [
	[
       {name: 'About', path: '/about'},
       {name: '2019 SHMP: New, Now, Next', path: '/about/new2019'},
       {name: 'The 2019 SHMP Planning Process', path: '/about/process'},
       {name: 'Implementation', path: '/about/implementation'},
       {name: 'Methodology', path: '/about/methodology'},
       {name: 'Guiding Authorities', path: '/about/authorities'},
       {name: 'Glossary', path: '/about/glossary'},
       {name: 'Mitigation News', path: '/about/news'},
       {name: 'Changelog', path: '/about/changelog'},
    ]
]