
// -- Landing Routes
import Landing from './pages/Landing'
// import PublicPlan from './pages/PublicPlan'

// -- Private Routes
import RiskIndex from './pages/auth/RiskIndex'
import Hazard from './pages/auth/RiskIndex/Hazard'
import Risk from './pages/auth/Vulnerabilities/Risk'
import Vulnerabilities from "./pages/auth/Vulnerabilities/Vulnerabilities"
import CMS from "./pages/auth/cms"
import NewContentPage from "./pages/auth/cms/NewContentPage"
import Plan from "pages/auth/SHMP"
import Strategies from "pages/auth/Strategies"
import Process from "pages/auth/Process"

import Capabilities from "pages/auth/Capabilities"
import About from "pages/auth/About/About"
import Compare from "pages/auth/RiskIndex/Compare"
import TestPage from "pages/auth/test_components/TestPage"
import CountyPage from "pages/auth/RiskIndex/CountyPage"
import Local from "pages/auth/Local/local"
import ManageCapabilities from "pages/auth/Capabilities/manage"
import NewCapability from "pages/auth/Capabilities/manage/NewCapability"

import FloodExplorer from "pages/auth/FloodExplorer"

import Comments from "pages/comments"

import Analysis from 'pages/auth/Analysis'
import ActionsIndex from 'pages/auth/Actions'
import ActionsView from 'pages/auth/Actions/worksheet/view'
import ActionWorksheet from 'pages/auth/Actions/worksheet/new'

// // -- Util Routes
import Login from './pages/Login'
import Signup from "./pages/Signup"
import Logout from './pages/Logout'
import NoMatch from './pages/404.js'

const routes = [
  Landing,
  ...RiskIndex,
  ...Hazard,
  ...Risk,
  ...Vulnerabilities,
  ...CMS,
  ...NewContentPage,
  ...Process,
  ...Capabilities,
  ...Strategies,
  ...Local,
  ...Compare,
  ...Plan,
  ...TestPage,
  ...CountyPage,
  ...About,
  ...ManageCapabilities,
  ...NewCapability,
  ...ActionWorksheet,
  ...ActionsIndex,
  ...ActionsView,
 
 ...Comments,
  
  Analysis,
  FloodExplorer,

  Login,
  Signup,
  Logout,
  NoMatch
]


export default routes