export default [
[
       {name: 'Risk', path: '/risk'},
       {name: 'Baseline Resilience', path: '/risk/bric'},
       {name: 'Built Environment', path: '/risk/builtenvironment'},
       {name: 'Flood Loss Estimates', path: '/risk/floodlossestimates'},
       {name: 'Floodplain Mapper', path: '/flood'},
],
[
       {name: 'Hazard History', path: '/hazardhistory'},
       {name: 'NFIP', path: '/risk/nfip'},
       {name: 'Population +/-', path: '/risk/vulnerabilities'},
       {name: 'State-owned Assets', path: '/risk/assetinventory'},
       {name: 'Vulnerable Populations', path: '/risk/vulnerablepopulations'},
    ],
    [
       {name: 'Dam Safety', path: '/risk/dams'},
      
    ]
]