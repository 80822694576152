let initialState = {
	ids :[
		"dhses",
		"nysdec",
		"nyserda",
		"dos",
		"doh",
		"dot",
		"dam",
		"dfs",
		"dol",
		"criminaljustice",
		"dhcr",
		"ocfs",
		"doccs",
		"opwdd",
		"ogs",
		"its",
		"omh",
		"parks",
		"otda",
		"psc",
		"canals",
		"nypa",
		"ovs",
		"gosr",
		"fema",
		"noaa",
		"dmna",
		"troopers",
		"nysed",
		"mta",
		"ba",
		"edc",
		"panynj",
		"suny",
		"redcross",
		"nycoem",
		"usgs",
		"usace",
		"nysfma",
		'lipa'
	],
	meta: {
		"dhses":{name: 'NYS Division of Homeland Security and Emergency Services'},
		"nysdec":{name: 'NYS Department of Environmental Conservation'},
		"nyserda":{name: 'NYSERDA'},
		"dos":{name: 'NYS Department of State'},
		"doh":{name: 'NYS Department of Health'},
		"dot":{name: 'NYS Department of Transportation'},
		"dam":{name: 'NYS Agriculture and Markets'},
		"dfs":{name: 'NYS Department of Financial Services'},
		"dol":{name: 'NYS Department of Labor'},
		"criminaljustice":{name: 'NYS  Division of Criminal Justice Service'},
		"dhcr":{name: 'NYS Homes and Community Renewal'},
		"ocfs":{name: 'NYS Office of Children and Family Services'},
		"doccs":{name: 'NYS Department of Corrections and Community Supervision'},
		"opwdd":{name: 'NYS Office for People with Developmental Disabilities'},
		"ogs":{name: 'NYS Office of General Services'},
		"its":{name: 'NYS Enterprise Information Security Office'},
		"omh":{name: 'NYS Office of Mental Health'},
		"parks":{name: 'NYS Office of Parks, Recreation and Historic Preservation'},
		"otda":{name: 'NYS Office of Temporary and Disability Assistance'},
		"psc":{name: 'New York Public Service Commission'},
		"canals":{name: 'NYS Thruway Authority'},
		"nypa":{name: 'NYS Power Authority'},
		"ovs":{name: 'NYS Office of Victim Services'},
		"gosr":{name: 'Governor\'s Office of Storm Recovery'},
		"fema":{name: 'FEMA'},
		"noaa":{name: 'NOAA'},
		"dmna":{name: 'NYS Division of Military and Naval Affairs'},
		"troopers":{name: 'New York State Police'},
		"nysed":{name: 'NYS Education Department'},
		"mta":{name: 'MTA'},
		"ba":{name: 'NYS Bridge Authority'},
		"edc":{name: 'NYS Economic Development Council'},
		"panynj":{name: 'Port Authority of New York and New Jersey'},
		"suny":{name: 'State University of New York'},
		"redcross":{name: 'American Red Cross'},
		"nycoem":{name: 'New York City Emergency Management'},
		"usgs":{name: 'United States Geological Survey'},
		"usace":{name: 'United States Army Corp of Engineers'},
		"nysfma":{name: 'New York State Floodplain and Stormwater Manager\'s Association'},
		"lipa":{name: 'Long Island Power Authority'}
	}
}


export default function agencyReduceer(state = initialState, action) {
  //const handler = ACTION_HANDLERS[action.type];
  return state;
}